import React from "react"
import Layout from "../components/layout"

const Contact = () => (
  <Layout title="Contact Us">
    <div className="w-full min-h-full flex flex-col justify-start items-center py-24">
      <h1 className="underline">Contact Us</h1>
      <table class="table-auto w-2/5 border-separate border border-slate-400">
        <thead>
          <tr>
          <th class="border border-slate-300">Name</th>
            <th class="border border-slate-300">Position</th>
            <th class="border border-slate-300">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td class="border border-slate-300">Jeff Demmers</td>
            <td class="border border-slate-300">Executive Director</td>
            <td class="border border-slate-300">
              {" "}
              <a href="mailto:jeffd@theinfinitevoiceproject.org" className="text-blue-500">
                jeffd@theinfinitevoiceproject.org
              </a>
            </td>
          </tr>
          <tr>
          <td class="border border-slate-300">Divine Fractal</td>
            <td class="border border-slate-300">Education Director</td>
            <td class="border border-slate-300">
              {" "}
              <a href="mailto:dg@theinfinitevoiceproject.org" className="text-blue-500">
                dg@theinfinitevoiceproject.org
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default Contact
